import en from './en.json';
import ng from './ng.json';
import zhHans from './zh-Hans.json';
import zhHant from './zh-Hant.json';
import ja from './ja.json';
import es from './es.json';
import co from './co.json';
import zh from './zh.json';
import br from './br.json';

export default {
  en,
  ng,
  zh,
  'zh-Hans': zhHans,
  'zh-Hant': zhHant,
  ja,
  es,
  co,
  br,
};
