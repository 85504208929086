import es from '../../locale/es.json';
import co from '../../locale/co.json';
import en from '../../locale/en.json';
import zh from '../../locale/zh.json';
import br from '../../locale/br.json';
import ng from '../../locale/ng.json';

// #ifdef H5
// language = uni.getLocale() || navigator.languages[1];
// #endif
// #ifdef APP-PLUS
// #endif
const messages = {
  es,
  co,
  en,
  zh,
  br,
  ng,
};

let language = uni.getLocale() || 'es'; // 默认语言
export const $t = (str) => {
  Object.keys(messages).forEach(function (key) {
    if (language.indexOf(key) === 0) {
      language = key;
    }
  });
  return messages[language][str] ? messages[language][str] : str;
};
