import request from '@request';

/**
 * 校验用户信息是否通过，重置密码的时候用
 */
// export function CheckUserName() {
//   return request.$post('/user/api/front/user/checkUserInfo')
// }

/**
 * 获取用户信息
 */
export function GetUserInfo() {
  // return request.$get('/user/userinfo/userCenter');
  return request.$get('/user/userinfo/get');
}

/**
 * 获取用户账户信息
 */
export function GetUserAccountInfo() {
  return request.$get('/user/userinfo/userAcctInfo');
}

/**
 * 获取站内信信息
 */
export function GetStationNotice() {
  return request.$post('/config/api/notice/station/count', {});
}

/**
 * 获取可提现金额
 */
export function GetWithdrawalAmt() {
  return request.$post('/user/account/calcWithdrawalAmt');
}

/**
 * 获取活动金额
 */
export function GetActivityAccount(id) {
  return request.$post('/user/activity/account/query', { activityId: id });
}

/**
 * 获取对应彩金子账户金额统计流水
 */
export function GetActivityAccountFlow(id) {
  return request.$post('/user/activity/account/flow/Statistic', { accountId: id });
}

/**
 * 修改用户信息
 * @param {*} obj
 */
export function UpdateUser(obj) {
  return request.$post('/user/userinfo/updateUser', { ...obj });
}

/**
 * 修改密码-校验并发送短信验证码
 * @param {*} param0
 */
export function CheckSendSmsCode({ mobile, userName }) {
  return request.$post('/user/api/front/user/checkUserInfo', { mobile, userName });
}

/**
 * 修改个人手机号-发送短信验证码
 * @param {*} param0
 */
export function SendSmsCode({ mobile, userName }) {
  return request.$post('/user/userinfo/sendVerifySms', { mobile, userName });
}

/**
 * 修改个人手机号-校验短信验证码
 * @param {*} param0
 */
export function VerifySmsCode({ mobile, smsCode }) {
  return request.$post('/user/userinfo/checkSmsVerifyCode', { mobile, smsCode });
}

/**
 * 发送邮箱验证码
 * @param {*} param0
 */
export function SendEmailCode({ email, userName }) {
  return request.$post('/user/userinfo/sendVerifyEmail', { email, userName });
}

/**
 * 校验邮箱验证码
 * @param {*} param0
 */
export function VerifyEmailCode({ code, email }) {
  return request.$post('/user/userinfo/checkEmailVerifyCode', { code, email });
}

/**
 * 认证接口
 * @param {*} param0
 */
// export function GetUserAuth({ backImg, frontImg, handBackImg, handFrontImg, idNo, cic }) {
//   return request.$post('/user/userinfo/userAuth', {
//     backImg,
//     frontImg,
//     handBackImg,
//     handFrontImg,
//     idNo,
//     cic,
//   });
// }

/**
 * 上传文件
 * @param {*} formData
 */
export function UploadFile(formData) {
  return request.$post('/system/upload', formData);
}

/**
 * 获取会员信息
 */
export function GetUserVipInfo() {
  return request.$get('/user/api/userinfo/memberInfo');
}

/**
 * 获取会员信息
 * @param {String} bankNumber :银行卡号
 * @param {String} bankCode :银行编码
 * bankName bankUser docNumber 20240410
 *
 */
export function userBankSave(param) {
  return request.$post('/user/userinfo/userBank/save', param);
}

/**
 * 取消提现
 * @param {Long} applyId :申请单ID
 * @param {String} reason : 取消原因
 */
export function withdrawalCancel(param) {
  return request.$post('/pay/api/pay/withdrawal/cancel', param);
}

/**
 *  获取分享链接接口
 */
export function getShareUrl(param) {
  return request.$post('/user/api/promote/url/get', param);
}
/**
 *  查询推广返佣账户接口
 */
export function userAccountQuery(param) {
  return request.$post('/promote/api/front/promote/rebate/account/query', param);
}
/**
 * 查询推广返佣账户明细接口
 */
export function userAccountDetailQuery(param) {
  return request.$post('/promote/api/promote/rebate/account/detail/query', param);
}

/**
 * 查询奖励账户
 */
export function userAccountRewardQuery(param) {
  return request.$get('/promote/api/front/promote/reward/account/query', param);
}
/**
 * 查询邀请用户明细
 */
export function userInviteUsersQuery(param) {
  return request.$post(`/promote/api/front/promote/reward/account/inviteUsers`, param);
}

/**
 * 查询邀请用户提现规则
 */
export function userInviteUsersRewardRule(param) {
  return request.$get(`/promote/api/promote/reward/account/rewardRule`, param);
}

/**
 * 随机生成用户名或密码信息
 */
export function genUserInfo({ genUser = true, genPwd = true }) {
  return request.$post(`/user/api/front/user/genUserInfo`, { genUser, genPwd });
}

/**
 * 进入交易所页面调用
 */
export function getCoinAccount() {
  return request.$post(`/coins/api/coins/account/first`);
}

/**
 * 获取挂机金币的
 */
export function getHookCoin() {
  return request.$post(`/coins/api/coins/account/init`);
}

/**
 * 同步挂机金额(TODO:进游戏之前调用，防止金币不同步，金币一直在默默增加)
 */
export function syncHookCoin() {
  return request.$post(`/coins/api/coins/account/sync`);
}

/**
 * tg注册账户
 */
export function registerTgUser(data) {
  return request.$post(`/auth/user/login/third`, data);
}
